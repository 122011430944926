import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    (searchQuery) ? (
      <Paper {...other}>
        <Typography gutterBottom align="center" variant="subtitle1">
          Not found
        </Typography>
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
        </Typography>
      </Paper>
    ) : (
      <Paper {...other}>
        <Typography gutterBottom align="center" variant="subtitle1">
        No Products Found
        </Typography>
        <Typography variant="body2" align="center">
        Add new products to showcase here.
        </Typography>
      </Paper>
    )
  );
}
