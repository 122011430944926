//materail

import { Box, Stack,Paper,Card,Typography, Grid } from "@material-ui/core"
import { useEffect, useRef } from "react"
import Scrollbar from "src/components/Scrollbar"
import { fData } from "src/utils/formatNumber"
import { fNumber } from "src/utils/formatNumber"
import { fDate } from "src/utils/formatTime"
export default function ChatbotMessageList({displayedComments,firstChat}){
  const scrollRef = useRef();
  console.log({firstChat});
  
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [displayedComments.length]);
    return(
        <Grid sx={{ mt: 4, }} xs={12} md={12}>
          <Stack spacing={3} sx={{  mt: 3,minHeight:'400px' }}>
          
        <Scrollbar scrollableNodeProps={{ ref: scrollRef }} >
        
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              color: 'text.disabled',
              textTransform: 'uppercase',
              display: 'flex', 
              alignItems: 'center',ml:'50%' 
            }}
          >
            Today
          </Typography>
          
        
            {displayedComments.map((comment, index) => (
              <Stack
                key={comment.id}
                direction="row"
                spacing={2}
                justifyContent={index % 2 === 1 ? 'flex-start' : 'flex-end'}
                // alignItems="center"
              >
                {index %2 === 1&&(
                    <Box>
                       <img  alt='icon' width='20px' height='20px' src='../Gif/chat-ai.gif'/>
                    </Box>
                   )} 
                <Paper
                  sx={{
                    // p: 1.5,
                     flexGrow: .5,
                     m:.5,
                    maxWidth: 200,
                    bgcolor: index % 2 === 0? 'primary.main' : 'background.neutral',
                    color: index % 2 !== 0 && 'text.secondary',
                    borderTopRightRadius:'10px',
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center',
                    textAlign: 'center', 
                  }}
                >
                              
                  <Typography
                    variant="body2"
                    sx={{
                      color: index % 2 === 0 ? 'common.white' : 'inherit',
                      maxWidth: '100%', 
                      whiteSpace: 'normal', 
                    }}
                  >
                    
                    {comment.text}
                  </Typography>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ sm: 'center' }}
                    justifyContent="space-between"
                    sx={{ mb: 0.5 }}
                  >
                  <Typography justifyContent={'flex-end'} 
                    variant="caption" sx={{ color: 'text.disabled' }}>
                      {comment.createdAt}
                    </Typography>
                    </Stack>
                </Paper>
              </Stack>
            ))}
        </Scrollbar>
          </Stack>
      </Grid>
      
    )
}